// src/components/HaColors.js
import React, { useState, useEffect } from 'react';
import './HaColors.css';  // Para o estilo do componente

const HaColors = () => {
  // Definindo o estado das cores para cada letra
  const [colors, setColors] = useState({
    h: '#ff0000', a: '#ff7f00', _: '#ffff00', c: '#00ff00', o: '#0000ff',
    l: '#4b0082', o2: '#9400d3', r: '#ff1493', s: '#ff4500'
  });

  const vibrantColors = ['#ff0000', '#ff7f00', '#ffff00', '#00ff00', '#0000ff', '#4b0082', '#9400d3', '#ff1493', '#ff4500'];

  // Função para trocar as cores vibrantes individualmente
  useEffect(() => {
    const interval = setInterval(() => {
      setColors(prevColors => {
        const newColors = { ...prevColors };
        Object.keys(newColors).forEach(letter => {
          newColors[letter] = vibrantColors[Math.floor(Math.random() * vibrantColors.length)];
        });
        return newColors;
      });
    }, 500);  // Troca de cor a cada 500ms

    // Limpeza do intervalo quando o componente for desmontado
    return () => clearInterval(interval);
  }, []);

  return (
    <div className="ha-colors">
      <h1>
        <span style={{ color: colors.h }}>h</span>
        <span style={{ color: colors.a }}>a</span>
        <span style={{ color: colors._ }}>-</span>
        <span style={{ color: colors.c }}>c</span>
        <span style={{ color: colors.o }}>o</span>
        <span style={{ color: colors.l }}>l</span>
        <span style={{ color: colors.o2 }}>o</span>
        <span style={{ color: colors.r }}>r</span>
        <span style={{ color: colors.s }}>s</span>
      </h1>

      {/* Adicionando o texto Coming Soon */}
      <p className="coming-soon">Coming Soon</p>
    </div>
  );
};

export default HaColors;
