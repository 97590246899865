// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("./assets/imgs/principal.jpg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* src/App.css */
.App {
  position: relative;  /* Permite sobrepor a camada de fundo */
  height: 100vh;       /* Garante que a imagem de fundo ocupe toda a tela */
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;        /* Cor do texto */
  overflow: hidden;
  background-image: url(${___CSS_LOADER_URL_REPLACEMENT_0___});  /* Caminho da imagem */
  background-size: cover;  /* A imagem cobre toda a tela */
  background-position: center; /* Centraliza a imagem */
  background-repeat: no-repeat; /* Impede que a imagem se repita */
}

/* Camada de fundo semi-transparente (overlay) */
.background-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(29, 29, 31, 0.5);  /* Cor #1d1d1f com 50% de opacidade */
  z-index: 1;  /* Coloca a camada de overlay sobre a imagem, mas abaixo do conteúdo */
  pointer-events: none;  /* Garante que a camada de overlay não interfira com a interação */
}

/* Componente de conteúdo */
.content {
  z-index: 2;  /* Garante que o conteúdo fique acima da camada de fundo */
  text-align: center;
}
`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAAA,gBAAgB;AAChB;EACE,kBAAkB,GAAG,uCAAuC;EAC5D,aAAa,QAAQ,oDAAoD;EACzE,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,YAAY,SAAS,iBAAiB;EACtC,gBAAgB;EAChB,yDAAoD,GAAG,sBAAsB;EAC7E,sBAAsB,GAAG,+BAA+B;EACxD,2BAA2B,EAAE,wBAAwB;EACrD,4BAA4B,EAAE,kCAAkC;AAClE;;AAEA,gDAAgD;AAChD;EACE,kBAAkB;EAClB,MAAM;EACN,OAAO;EACP,WAAW;EACX,YAAY;EACZ,uCAAuC,GAAG,qCAAqC;EAC/E,UAAU,GAAG,sEAAsE;EACnF,oBAAoB,GAAG,kEAAkE;AAC3F;;AAEA,2BAA2B;AAC3B;EACE,UAAU,GAAG,0DAA0D;EACvE,kBAAkB;AACpB","sourcesContent":["/* src/App.css */\n.App {\n  position: relative;  /* Permite sobrepor a camada de fundo */\n  height: 100vh;       /* Garante que a imagem de fundo ocupe toda a tela */\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  color: white;        /* Cor do texto */\n  overflow: hidden;\n  background-image: url('./assets/imgs/principal.jpg');  /* Caminho da imagem */\n  background-size: cover;  /* A imagem cobre toda a tela */\n  background-position: center; /* Centraliza a imagem */\n  background-repeat: no-repeat; /* Impede que a imagem se repita */\n}\n\n/* Camada de fundo semi-transparente (overlay) */\n.background-overlay {\n  position: absolute;\n  top: 0;\n  left: 0;\n  width: 100%;\n  height: 100%;\n  background-color: rgba(29, 29, 31, 0.5);  /* Cor #1d1d1f com 50% de opacidade */\n  z-index: 1;  /* Coloca a camada de overlay sobre a imagem, mas abaixo do conteúdo */\n  pointer-events: none;  /* Garante que a camada de overlay não interfira com a interação */\n}\n\n/* Componente de conteúdo */\n.content {\n  z-index: 2;  /* Garante que o conteúdo fique acima da camada de fundo */\n  text-align: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
