import './App.css';
import HaColors from './components/HaColors/HaColors';

function App() {
  return (
    <div className="App">
      {/* Camada de fundo semi-transparente */}
      <div className="background-overlay"></div>

      {/* Componente HaColors */}
      <div className="content">
        <HaColors />
      </div>
    </div>
  );
}

export default App;
