// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* src/components/HaColors.css */
.ha-colors {
    text-align: center;
    color: white;
    font-family: Arial, sans-serif;
  }
  
  .ha-colors h1 {
    font-size: 4rem;  /* Aumente o tamanho das letras para dar mais destaque */
    margin-bottom: 20px;  /* Espaço entre o título e o "Coming Soon" */
  }
  
  .ha-colors .coming-soon {
    font-size: 1.5rem;  /* Tamanho da fonte para "Coming Soon" */
    color: rgba(255, 255, 255, 0.8);  /* Cor branca, com leve opacidade */
    letter-spacing: 2px;  /* Espaçamento entre as letras para dar um efeito interessante */
    font-weight: 100;   /* Deixa o texto mais destacado */
    margin-top: 10px;    /* Dá um pequeno espaço acima do texto */
    text-transform: uppercase; /* Coloca o texto em maiúsculas */
  }
  `, "",{"version":3,"sources":["webpack://./src/components/HaColors/HaColors.css"],"names":[],"mappings":"AAAA,gCAAgC;AAChC;IACI,kBAAkB;IAClB,YAAY;IACZ,8BAA8B;EAChC;;EAEA;IACE,eAAe,GAAG,wDAAwD;IAC1E,mBAAmB,GAAG,4CAA4C;EACpE;;EAEA;IACE,iBAAiB,GAAG,wCAAwC;IAC5D,+BAA+B,GAAG,mCAAmC;IACrE,mBAAmB,GAAG,gEAAgE;IACtF,gBAAgB,IAAI,iCAAiC;IACrD,gBAAgB,KAAK,wCAAwC;IAC7D,yBAAyB,EAAE,iCAAiC;EAC9D","sourcesContent":["/* src/components/HaColors.css */\n.ha-colors {\n    text-align: center;\n    color: white;\n    font-family: Arial, sans-serif;\n  }\n  \n  .ha-colors h1 {\n    font-size: 4rem;  /* Aumente o tamanho das letras para dar mais destaque */\n    margin-bottom: 20px;  /* Espaço entre o título e o \"Coming Soon\" */\n  }\n  \n  .ha-colors .coming-soon {\n    font-size: 1.5rem;  /* Tamanho da fonte para \"Coming Soon\" */\n    color: rgba(255, 255, 255, 0.8);  /* Cor branca, com leve opacidade */\n    letter-spacing: 2px;  /* Espaçamento entre as letras para dar um efeito interessante */\n    font-weight: 100;   /* Deixa o texto mais destacado */\n    margin-top: 10px;    /* Dá um pequeno espaço acima do texto */\n    text-transform: uppercase; /* Coloca o texto em maiúsculas */\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
